import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Progress, StyledProgress } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Progress" title="Progress" mdxType="PageTitle" />
    <h2 {...{
      "id": "standard-progress-bars"
    }}>{`Standard progress bars`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Rhythm grouped py={2}>\n    <h3>Small bar</h3>\n    <Progress color=\"primary\" percent={80} size=\"small\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Medium bar</h3>\n    <Progress color=\"primary\" percent={80} size=\"medium\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Large bar</h3>\n    <Progress color=\"primary\" percent={80} size=\"large\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Floating bar</h3>\n    <Progress floating color=\"primary\" percent={80} size=\"medium\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Animated bar</h3>\n    <Progress animated color=\"primary\" percent={80} size=\"large\" />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Small bar</h3>
      <Progress color="primary" percent={80} size="small" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Medium bar</h3>
      <Progress color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Large bar</h3>
      <Progress color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Floating bar</h3>
      <Progress floating color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Animated bar</h3>
      <Progress animated color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "rounded-progress-bars"
    }}>{`Rounded progress bars`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Rhythm grouped py={2}>\n    <h3>Small bar</h3>\n    <Progress rounded color=\"primary\" percent={80} size=\"small\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Medium bar</h3>\n    <Progress rounded color=\"primary\" percent={80} size=\"medium\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Large bar</h3>\n    <Progress rounded color=\"primary\" percent={80} size=\"large\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Floating bar</h3>\n    <Progress floating rounded color=\"primary\" percent={80} size=\"medium\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Animated bar</h3>\n    <Progress animated rounded color=\"primary\" percent={80} size=\"large\" />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Small bar</h3>
      <Progress rounded color="primary" percent={80} size="small" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Medium bar</h3>
      <Progress rounded color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Large bar</h3>
      <Progress rounded color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Floating bar</h3>
      <Progress floating rounded color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Animated bar</h3>
      <Progress animated rounded color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "vertical-progress-bars"
    }}>{`Vertical progress bars`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Flex direction=\"row\">\n    <Rhythm mx={2} style={{ height: 120 }}>\n      <Progress\n        rounded\n        color=\"primary\"\n        orientation=\"vertical\"\n        percent={80}\n        size=\"small\"\n      />\n      <Progress\n        rounded\n        color=\"primary\"\n        orientation=\"vertical\"\n        percent={80}\n        size=\"medium\"\n      />\n      <Progress\n        rounded\n        color=\"primary\"\n        orientation=\"vertical\"\n        percent={80}\n        size=\"large\"\n      />\n      <Progress\n        floating\n        rounded\n        color=\"primary\"\n        orientation=\"vertical\"\n        percent={80}\n        size=\"medium\"\n      />\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="row" mdxType="Flex">
      <Rhythm mx={2} style={{
            height: 120
          }} mdxType="Rhythm">
        <Progress rounded color="primary" orientation="vertical" percent={80} size="small" mdxType="Progress" />
        <Progress rounded color="primary" orientation="vertical" percent={80} size="medium" mdxType="Progress" />
        <Progress rounded color="primary" orientation="vertical" percent={80} size="large" mdxType="Progress" />
        <Progress floating rounded color="primary" orientation="vertical" percent={80} size="medium" mdxType="Progress" />
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "multi-segment-progress-bars"
    }}>{`Multi-segment progress bars`}</h2>
    <h3 {...{
      "id": "medium-segmented-bar"
    }}>{`Medium segmented bar`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Rhythm grouped py={2}>\n    <h3>Medium segmented bar</h3>\n    <Progress\n      spaced\n      color=\"primary\"\n      data={[\n        { color: \'danger\', percent: 10 },\n        { color: \'warning\', percent: 20 },\n        { color: \'success\', percent: 40 },\n      ]}\n    />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Large segmented bar</h3>\n    <Progress\n      rounded\n      spaced\n      color=\"primary\"\n      data={[\n        { color: \'danger\', percent: 10 },\n        { color: \'warning\', percent: 20 },\n        { color: \'success\', percent: 40 },\n      ]}\n      size=\"large\"\n    />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Medium segmented bar</h3>
      <Progress spaced color="primary" data={[{
            color: 'danger',
            percent: 10
          }, {
            color: 'warning',
            percent: 20
          }, {
            color: 'success',
            percent: 40
          }]} mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Large segmented bar</h3>
      <Progress rounded spaced color="primary" data={[{
            color: 'danger',
            percent: 10
          }, {
            color: 'warning',
            percent: 20
          }, {
            color: 'success',
            percent: 40
          }]} size="large" mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "state-progress-bars"
    }}>{`State progress bars`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Rhythm grouped py={2}>\n    <h3>Success</h3>\n    <Progress color=\"success\" percent={60} />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Warning</h3>\n    <Progress color=\"warning\" percent={40} />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Danger</h3>\n    <Progress color=\"danger\" percent={20} />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Primary</h3>\n    <Progress color=\"primary\" percent={20} />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Success</h3>
      <Progress color="success" percent={60} mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Warning</h3>
      <Progress color="warning" percent={40} mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Danger</h3>
      <Progress color="danger" percent={20} mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Primary</h3>
      <Progress color="primary" percent={20} mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "quiet-progress-bars"
    }}>{`Quiet progress bars`}</h3>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Rhythm grouped py={2}>\n    <h3>Quiet</h3>\n    <Progress color=\"success\" percent={60} volume=\"quiet\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Quieter</h3>\n    <Progress color=\"warning\" percent={40} volume=\"quieter\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Quietest</h3>\n    <Progress color=\"danger\" percent={20} volume=\"quietest\" />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Quiet</h3>
      <Progress color="success" percent={60} volume="quiet" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Quieter</h3>
      <Progress color="warning" percent={40} volume="quieter" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Quietest</h3>
      <Progress color="danger" percent={20} volume="quietest" mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-progress-bars"
    }}>{`Contrast progress bars`}</h3>
    <Playground __position={7} __code={'<ThemeWrapper contrast>\n  <Rhythm grouped py={2}>\n    <h3>Small bar</h3>\n    <Progress contrast rounded color=\"primary\" percent={80} size=\"small\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Medium bar</h3>\n    <Progress contrast rounded color=\"primary\" percent={80} size=\"medium\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Large bar</h3>\n    <Progress contrast rounded color=\"primary\" percent={80} size=\"large\" />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Floating bar</h3>\n    <Progress\n      contrast\n      floating\n      rounded\n      color=\"primary\"\n      percent={80}\n      size=\"medium\"\n    />\n  </Rhythm>\n  <Rhythm grouped py={2}>\n    <h3>Animated bar</h3>\n    <Progress\n      animated\n      contrast\n      rounded\n      color=\"primary\"\n      percent={80}\n      size=\"large\"\n    />\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Small bar</h3>
      <Progress contrast rounded color="primary" percent={80} size="small" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Medium bar</h3>
      <Progress contrast rounded color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Large bar</h3>
      <Progress contrast rounded color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Floating bar</h3>
      <Progress contrast floating rounded color="primary" percent={80} size="medium" mdxType="Progress" />
    </Rhythm>
    <Rhythm grouped py={2} mdxType="Rhythm">
      <h3>Animated bar</h3>
      <Progress animated contrast rounded color="primary" percent={80} size="large" mdxType="Progress" />
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-progress-bar"
    }}>{`Styled progress bar`}</h3>
    <Playground __position={8} __code={'<StyledProgress\n  backgroundColor=\"#000\"\n  percent={80}\n  segmentColor=\"linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledProgress backgroundColor="#000" percent={80} segmentColor="linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)" mdxType="StyledProgress" />
    </Playground>
    <h3 {...{
      "id": "styled-animated-progress-bar"
    }}>{`Styled animated progress bar`}</h3>
    <Playground __position={9} __code={'<StyledProgress\n  animated\n  rounded\n  backgroundColor=\"rgba(0, 0, 0, .1)\"\n  percent={80}\n  segmentAnimationColor=\"rgba(0, 0, 0, .1)\"\n  segmentColor=\"rgba(0, 0, 0, .1)\"\n  size=\"large\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Progress,
      StyledProgress,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledProgress animated rounded backgroundColor="rgba(0, 0, 0, .1)" percent={80} segmentAnimationColor="rgba(0, 0, 0, .1)" segmentColor="rgba(0, 0, 0, .1)" size="large" mdxType="StyledProgress" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Progress} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      